import {
    request
} from "./request";


// 获取品牌
export const getlist = (data) => {
    return request({
        url: 'index.php/api/web/getorderlist',
        method: 'post',
        data
    })
}
// 获取品牌
export const uploadimage = (data) => {
    return request({
        url: 'index.php/api/web/uploadimage',
        method: 'post',
        data
    })
}
export const getoneorderinfo = (data) => {
    return request({
        url: 'index.php/api/web/getoneorderinfo',
        method: 'post',
        data
    })
}
